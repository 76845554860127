import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import { store } from "./redux/store"
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Amplify, AuthModeStrategyType} from 'aws-amplify';
import { Toaster } from "react-hot-toast"
import awsConfig from './aws-exports';
import AuthProvider from "./contexts/AuthContext";
import {LeadProvider} from "./contexts/LeadContexts";
import {Provider} from "react-redux";
import {ReportProvider} from "./contexts/ReportContexts";


Amplify.configure({
    ...awsConfig,
      DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});
const App = lazy(() => import("./App"))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Toaster position="top-right" reverseOrder={false} />
        <AuthProvider>
            <LeadProvider>
                <ReportProvider>
                    <App />
                </ReportProvider>
            </LeadProvider>
      </AuthProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
