import React, { useState } from "react";
import API from '../Api'
import {listReports} from "../graphql/queries";

export const ReportContext = React.createContext({
    allReports : [],
    isReportCacheEmpty: true,
    fetchAllReports: async ()=> [],
    fetchPaginatedReports: async () => {},
    refreshReportCache: async () =>[]
})

export const ReportProvider = ({ children }) => {
    const [allReports, setAllReports] = useState([])
    const [isReportCacheEmpty, setIsReportCacheEmpty] = useState(true)

    const fetchAllReports = async () => {
        try {
            const {data} = await API.graphql({
                query: listReports,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            setAllReports(data.listReports.items)
            localStorage.setItem('allReports', data.listReports.items)
            setIsReportCacheEmpty(false)
            console.log('All Reports', data.listReports.items)
            return data.listReports.items
        } catch (err) {
            console.log('Error fetching all Reports', err)
        }
    }

    const fetchPaginatedReports = async () => {
         try {
             const variables = {
                  limit: 20
                  // add filter as needed
                }
            const {data} = await API.graphql({
                query: listReports,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
             const { items } = data.listReports;
            setAllReports(items)
            localStorage.setItem('allReports', items)
            setIsReportCacheEmpty(false)
            return items
        } catch (err) {
            console.log('Error fetching Reports', err)
        }
    }


    const refreshReportCache = async () => {
        try {
            console.log('Refreshing Report Cache')
            await fetchAllReports()
        } catch (err) {
            console.log(err)
        }
    }

    const value = {
        allReports,
        isReportCacheEmpty,
        fetchAllReports,
        refreshReportCache,
        fetchPaginatedReports
    }

    return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
}