import React, { useState } from "react";
import API from '../Api'
import {listLeads} from "../graphql/queries";

export const LeadContext = React.createContext({
    allLeads : [],
    isLeadCacheEmpty: true,
    fetchAllLeads: async ()=> [],
    fetchPaginatedLeads: async () => {},
    refreshLeadCache: async () =>[]
})

export const LeadProvider = ({ children }) => {
    const [allLeads, setAllLeads] = useState([])
    const [isLeadCacheEmpty, setIsLeadCacheEmpty] = useState(true)

    const fetchAllLeads = async () => {
      let allLeads = [];

      try {
        let nextToken = null;

        do {
          const { data } = await API.graphql({
            query: listLeads,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: { nextToken }, // Pass the nextToken as a variable
          });

          const { items, nextToken: newNextToken } = data.listLeads;

          allLeads = [...allLeads, ...items]; // Append the newly fetched leads to the existing leads

          nextToken = newNextToken; // Update the nextToken for the next iteration
        } while (nextToken); // Continue looping until there is no nextToken
        localStorage.setItem('allLeads', JSON.stringify(allLeads));

        return allLeads;
      } catch (err) {
        console.log('Error fetching all Leads', err);
      }
    };

    const fetchPaginatedLeads = async () => {
         try {
             const variables = {
                  limit: 20
                  // add filter as needed
                }
            const {data} = await API.graphql({
                query: listLeads,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
             console.log('The data: ', data)
             const { items } = data.listLeads;
            setAllLeads(items)
            localStorage.setItem('allLeads', items)
            localStorage.setItem('NextToken', data.nextToken)
            setIsLeadCacheEmpty(false)
            return items
        } catch (err) {
            console.log('Error fetching Leads', err)
        }
    }


    const refreshLeadCache = async () => {
        try {
            console.log('Refreshing Lead Cache')
            await fetchAllLeads()
        } catch (err) {
            console.log(err)
        }
    }

    const value = {
        allLeads,
        isLeadCacheEmpty,
        fetchAllLeads,
        refreshLeadCache,
        fetchPaginatedLeads
    }

    return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>
}